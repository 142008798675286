import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlightInfoService {

  constructor(private http: HttpClient) { }

  getFlightInfo(airLine: string, flightDate: string, utcDate: boolean, flightNumber: string, departureAirportCode: string): Observable<FlightInfoWsResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Ocp-Apim-Subscription-Key': environment.flightInfoSubscriptionKey,
        'Application-Id': 'b2b',
      })
    };

    var flightInfoServiceUrl = environment.apiBaseUrl

    if (utcDate) {
      flightInfoServiceUrl =  flightInfoServiceUrl  + '/3/flightOps/ioc/flightInfoWS/airline/' + airLine + '/flights/flightlegs?uriFlightLegParams.flightDateUTC='
      + flightDate + '&uriFlightLegParams.flightNumbers=' + flightNumber + '&uriFlightLegParams.iataDepartureAirportCode=' + departureAirportCode;
    } else {
      flightInfoServiceUrl =  flightInfoServiceUrl  + '/1/flightOps/ioc/flightInfoWS/airlines/' + airLine + '/flights/flightlegs?uriFlightLegParams.flightDate='
      + flightDate + '&uriFlightLegParams.flightNumbers=' + flightNumber + '&uriFlightLegParams.iataDepartureAirportCode=' + departureAirportCode;
    }

    console.log(flightInfoServiceUrl);
    
    return this.http.get<FlightInfoWsResponse>(flightInfoServiceUrl, httpOptions).pipe(
      catchError(this.handleError<FlightInfoWsResponse>('get', null))
    );
  }
  
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);
      
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}


export interface FlightIdentifier {
  FlightDate: string;
  IataOperatingAirlineCode: string;
  FlightNumber: string;
  IataDepartureAirportCode: string;
  IataArrivalAirportCode: string;
  OperationalSuffix: string;
  FlightDateUTC: string;
  LocalDate: string;
}

export interface FlightLegIdentifier {
  FlightDate: string;
  IataOperatingAirlineCode: string;
  FlightNumber: string;
  IataDepartureAirportCode: string;
  IataArrivalAirportCode: string;
  OperationalSuffix: string;
}

export interface AircraftInformation {
  AircraftType: string;
  IataAircraftType: string;
  AircraftFleetId: string;
  AircraftRegistration: string;
  MaintenanceAircraftType: string;
  SeatmapConfiguration: string;
  FClassSeats: string;
  YClassSeats: string;
}

export interface AirgroupOperationalStatus {
  AirgroupOpsType: string;
  AirgroupOpsReason: string;
}

export interface ScheduledFlightTimes {
  StdUtc: string;
  StaUtc: string;
}

export interface EstimatedFlightTimes {
  EtdUtc: string;
  EtaUtc: string;
}

export interface ActualFlightTimes {
  OffUtc: string;
  OnUtc: string;
  InUtc: string;
  OutUtc: string;
}

export interface FlightTimes {
  ScheduledFlightTimes: ScheduledFlightTimes;
  EstimatedFlightTimes: EstimatedFlightTimes;
  ActualFlightTimes: ActualFlightTimes;
}

export interface LegData {
  AircraftInformation: AircraftInformation;
  AirgroupOperationalStatus: AirgroupOperationalStatus;
  DepartureOccurence: string;
  ArrivalOccurence: string;
  FlightTimes: FlightTimes;
  IataActualDepartureAirportCode: string;
  IataActualArrivalAirportCode: string;
  IcaoDepartureAirportCode: string;
  IcaoArrivalAirportCode: string;
  IcaoActualDepartureAirportCode: string;
  IcaoActualArrivalAirportCode: string;
  IataMarketingAirlineCode: string;
  ScheduleIataAircraftType: string;
}

export interface Self {
  Href: string;
}

export interface Links {
  Detail?: any;
  Self: Self;
}

export interface FlightLeg {
  FlightLegIdentifier: FlightLegIdentifier;
  LegData: LegData;
  Links: Links;
}

export interface Flight {
  FlightIdentifier: FlightIdentifier;
  FlightLegs: FlightLeg[];
  Links: Links;
}

export interface FlightInfoWsResponse {
  Flight: Flight[];
}


