<div id="appHeader" style="position: sticky; top: 0; ">
    <div style="height: 60px; display: flex;">
        <div style="width: 60%;">
            <a href="/home">
                <img src="assets/alaska-logo.svg" alt="Alaska Airlines Logo" style="padding-left: 20px; padding-top: 15px;"/>
            </a>
            <div style="font-size: 12px; color: white; padding-left: 20px;">TEAM MESSAGING 
                <span *ngIf='!environment.production' style="padding-left: 8px; font-weight: 800;">*TEST*</span> 
            </div>
        </div>
        <div *ngIf='channel' style="width: 40%; text-align: right; vertical-align: middle; line-height: 60px; padding-right: 15px; font-size: 16px;" >
            <a href="/home" style="color: white; font-weight: 600;" class="link">Join new flight</a>
        </div>
    </div>
    <div *ngIf='channel' style="height: 66px; color: #212223; border-bottom: 1px solid #cccccc; background-color: white;">
        <div style="width: 320px; padding-top: 8px; padding-left: 16px;">
            <section>
                <div style="font-size: 20px; font-weight: 500;">{{ flightInfoFlightNumber }}</div>
                <div style="font-size: 20px; font-weight: 500;">{{ flightInfoDepartureAirport }} <img src="assets/arrow.png" alt="&#x279c;" style="padding-left: 2px; padding-right: 2px; height: auto; width: 15px;"/> {{ flightInfoArrivalAirport }}</div>
                <div style="font-size: 16px; line-height: 23px;">{{ flightInfoDepartureTime }}</div>
                <div style="font-size: 16px; line-height: 23px;">{{ flightInfoDepartureDate }}</div>
            </section>
        </div>
    </div>
    <div *ngIf='!channel' style="font-size: 30px; color: #212223; padding-left: 16px; padding-top: 18px; padding-bottom: 12px; border-bottom: 1px solid #cccccc; background-color: white;">Flight Search</div>

    <div id="errorMessage" *ngIf='showError' class="error-container">
        <div *ngIf='errorCode == ERR_FLIGHT_NOT_FOUND'>
            <span style="font-weight: 600;">Flight not found: </span>Check the flight number, date and departure airport. The flight may have been cancelled. 
        </div>
        <div *ngIf='errorCode == ERR_WRONG_AIRLINE'>
            <span style="font-weight: 600;">Flight could not be joined: </span>This flight is operated by another airline. 
        </div>
        <div *ngIf='errorCode == ERR_NOT_SIGNED_IN_TO_TWILIO'>
            <span style="font-weight: 600;">Unable to connect to Team Messaging.</span> Please open a new browser window and sign in again.
        </div>
        <div *ngIf='errorCode == ERR_NOT_CONNECTED_IN_TO_CHANNEL'>
            <span style="font-weight: 600;">You are no longer connected to this flight: </span>
            <a style="color: white; font-weight: 900; cursor: pointer;" class="link" (click)="onRejoin()">Rejoin flight</a>
        </div>
        <div *ngIf='errorCode == ERR_NO_AIRLINE_SET_LOGIN_AGAIN'>
            <span style="font-weight: 600;">Flight could not be joined: </span>Please open a new browser window and sign in again.
        </div>
    </div>        
</div>

<div class="home-container">
    <div class="spinner" *ngIf="loading">
        <img src="assets/alaska-loader.gif" alt="Loading..." />
    </div>

    <div *ngIf='!channel' style="padding: 24px 16px 0px 16px">
        <form [formGroup]="flightForm" (ngSubmit)="onSubmit()" >
            <div class="floating-label" style="padding-bottom: 20px;">
                <select formControlName="departureDate" class="floating-select" style="height: 44px; width: 288px;">
                    <option *ngFor="let d of departureDates" [ngValue]="d">
                    {{ d }}
                    </option>
                </select>      
                <label [class.has-value]="true">Local departure date </label>
            </div>
            <div class="floating-label" style="padding-bottom: 20px;">
                <input type="text" formControlName="flightNumber" class="floating-input" maxlength="4" style="height: 44px; width: 288px;" placeholder=" ">
                <label>Flight number</label>
            </div>
            <div class="floating-label" style="padding-bottom: 24px;">
                <input type="text" formControlName="departureAirport" class="floating-input" style="text-transform: uppercase; height: 44px; width: 288px;" maxlength="3" placeholder=" ">
                <label>Departure airport</label>
            </div>
            <button type="submit" [disabled]="!flightForm.valid || !connectedToTwilio" class="button-primary" style="width: 288px;">Join Flight</button>
        </form>
    </div>

    <div *ngIf='channel' id='channel-container' class="channel-container" >
        <div class='message-container' *ngFor="let message of airtalkMessages">
            <div class='message-pill' [ngStyle]="{ 'background' : message.pillColor }">{{message.displayName}}</div>
            <span class="message-time">{{message.senderName}}</span>
            <span class="message-time">{{message.displayTime}}</span>
            <div style="display: flex">
                <div class='message-body'>{{message.body}}</div>
            </div>
        </div>
        <div *ngIf="channel && airtalkMessages.length == 0" class="message-no-messages">
            No messages for this flight.
        </div>
    </div>
</div>
<div style="position: sticky; bottom: 0; background-color: #eeeeee;">
    <div style="height: 16px; background-color: #eeeeee;"></div>
    <form *ngIf="channel" [formGroup]='messageForm' (ngSubmit)='sendMessage()'>
        <div style="margin: 0px 16px 0px 16px; padding-bottom: 25px; max-width: 736px; display: flex;">
            <textarea #messageInput rows="1" formControlName="messageText" class="message-input-text-area" oninput="auto_height(this)" placeholder="Type a new message." maxlength="280"></textarea>
            <button type="submit" class="button-primary" [disabled]="!messageForm.valid || disableSend || !connectedToTwilio" style="min-width: 76px; width: 76px; margin-left: 12px; max-height: 44px;">Send</button>        
        </div>
    </form>
</div>
