import { Component, OnInit, Input, HostListener, Renderer2, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AirtalkService, AirtalkTokenResponse, AirTalkConfiguration } from '../services/airtalk.service';
import { FlightInfoService, Flight, FlightLeg } from '../services/flight-info.service';
import * as Twilio from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  environment = environment;
  
  flightForm = new FormGroup({
    departureDate: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}')]),
    flightNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{1,4}')]),
    departureAirport: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]{3}')])
  });
  messageForm = new FormGroup({
    messageText: new FormControl('', [Validators.required])
  });

  airtalkMessages: AirtalkMessage[] = [];
  departureDates = [moment().add(-1, "day").format('MM/DD/YYYY'), moment().format('MM/DD/YYYY'), moment().add(1, "day").format('MM/DD/YYYY')];

  flightInfoFlightNumber: String;
  flightInfoDepartureAirport: String;
  flightInfoArrivalAirport: String;
  flightInfoDepartureTime: String;
  flightInfoDepartureDate: String;

  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document, private airtalkService: AirtalkService, private flightInfoService: FlightInfoService, private route: ActivatedRoute) { }

  client: Twilio.Client;
  channel: Channel;
  loading: boolean;

  showError: Boolean = false;
  errorCode: Number = 0;
  ERR_FLIGHT_NOT_FOUND = 901;
  ERR_NOT_SIGNED_IN_TO_TWILIO = 902;
  ERR_NOT_CONNECTED_IN_TO_CHANNEL = 903;
  ERR_WRONG_AIRLINE = 904;
  ERR_NO_AIRLINE_SET_LOGIN_AGAIN = 905;
  channelName: string;

  departureDate: string;
  flightNumber: string;
  departureAirport: string;
  disableSend: boolean = false;
  airtalkConfiguration: AirTalkConfiguration;
  utcDate: boolean = false;

  @Input() airtalkTokenResponse: AirtalkTokenResponse;
  @Input() connectedToTwilio = false;
  @ViewChild('messageInput') messageInputTextArea: ElementRef; 

  @HostListener('window:beforeunload', ['$event'])
  async unloadHandler(event: Event) {
    if (this.channel) {
      event.returnValue = true;
      this.showErrorMessage(this.ERR_NOT_CONNECTED_IN_TO_CHANNEL);
      this.disableSend = true;
      await this.channel.leave();
      
    }
  }
  
  readonly defaultConfig: AirTalkConfiguration = { roles: [  
    {
      role: 'unknown',
      displayName: 'User',
      abbreviation: '',
      themes: {default: { textColor: '#000000', backgroundColor: '#A6A6A6'}},
      positions: null
    },
    {
      role: 'boardingAgent',
      displayName: 'Boarding',
      abbreviation: 'CSA',
      themes: {default: { textColor: '#000000', backgroundColor: '#91BE62'}},
      positions: null
    },
    {
      role: 'controlAgent',
      displayName: 'Control',
      abbreviation: 'CSA',
      themes: {default: { textColor: '#000000', backgroundColor: '#D0F8A6'}},
      positions: null
    },
    {
      role: 'sectorManager',
      displayName: 'SOC/NOC',
      abbreviation: 'SOC',
      themes: {default: { textColor: '#000000', backgroundColor: '#FFDB67'}},
      positions: null
    },
    {
      role: 'flightAttendant',
      displayName: 'FA',
      abbreviation: 'FA',
      themes: {default: { textColor: '#000000', backgroundColor: '#6BB7FB'}},
      positions: [
        {
          position: 'fa',
          displayName: 'A'
        },
        {
          position: 'fb',
          displayName: 'B'
        },
        {
          position: 'fc',
          displayName: 'C'
        },
        {
          position: 'fd',
          displayName: 'D'
        },
        {
          position: 'fe',
          displayName: 'E'
        },
        {
          position: 'ff',
          displayName: 'F'
        },
        {
          position: 'deadheader',
          displayName: 'DH'
        },
        {
          position: 'preboarder',
          displayName: 'Preboarder'
        },
        {
          position: 'management',
          displayName: 'MGMT'
        }
      ]
    }
  ]};


  ngOnInit(): void {

    console.log('HomeComponent ngOnInit called');

    //inject a javascript snippet to automatically adjust the height
    // of the message input area
    // CREDIT: Nicky, https://stackoverflow.com/questions/38088996/adding-script-tags-in-angular-component-template
    let script = this._renderer2.createElement('script');
    script.type = `text/javascript`;
    script.text = `
          function auto_height(elem) {  /* javascript */
              elem.style.height = "1px";
              elem.style.height = (elem.scrollHeight)+"px";
          }
    `;
    this._renderer2.appendChild(this._document.body, script);
    //end javascript

    this.clearFlightInfoHeader();

    //map query string keys to be all lowercase, and uppercase the values
    var params = { };
    const queryParamEntries = this.route.snapshot.queryParamMap.keys
      .map(key => params[key.toLowerCase()] = this.route.snapshot.queryParamMap.get(key).toUpperCase());

    //now look for deep linking query string parameters using lowercase keys
    this.departureDate = params['utcdate'];
    this.flightNumber = params['flight'];
    this.departureAirport = params['origin'];

    //In order to support deep linking. Display spinner if we have query string parameters.
    if (this.departureDate && this.flightNumber && this.departureAirport) {
      console.log('Deep linking requested to: ', this.departureDate, this.flightNumber, this.departureAirport);
      this.utcDate = true;
    }

    this.flightForm.patchValue({ departureDate: moment().format('MM/DD/YYYY')})

    this.airtalkService.getTwilioToken().subscribe((result) => {
      this.airtalkTokenResponse = result;
      this.loginToTwilio();
    });

    //Get airtalk configurations
    this.airtalkService.getConfiguration().subscribe((result) => {
      if (result == null || result.roles == null || result.roles.length == 0) {
        this.airtalkConfiguration = this.defaultConfig
      }
      else {
        this.airtalkConfiguration = result
      }
    });
  }

  onSubmit() {
    console.log('onSubmit called');
    console.log(this.flightForm.value);
    
    this.loadChatUi();

  }

  onRejoin() {
    if(this.channelName) {
      this.joinChannel(this.channelName);
    }
  }

  loadChatUi() {
    console.log('loadChatUi');
    const departureDate = moment(this.flightForm.get('departureDate').value, 'MM/DD/YYYY');
    if (!departureDate.isValid()) {
      console.log('Invalid departure date: ' + this.flightForm.get('departureDate').value);
      this.flightForm.get('departureDate').setErrors({'invalid': true});
      //utcDate is set to false so that it will allow user to search flight based on the local date.
      this.utcDate = false;
      return;
    }

    const flightNumber = Number(this.flightForm.get('flightNumber').value);
    if (isNaN(flightNumber) || this.flightForm.get('flightNumber').value.length == 0) {
      console.log('Invalid flight number: ' + this.flightForm.get('flightNumber').value);
      this.flightForm.get('flightNumber').setErrors({'invalid': true});
      //utcDate is set to false so that it will allow user to search flight based on the local date.
      this.utcDate = false;
      return;
    }

    const departureAirport = this.flightForm.get('departureAirport').value.toUpperCase();
    
    if (departureAirport.length != 3) {
      console.log('Invalid departure airport: ' + this.flightForm.get('departureAirport').value);
      this.flightForm.get('departureAirport').setErrors({'invalid': true});
      //utcDate is set to false so that it will allow user to search flight based on the local date.
      this.utcDate = false;
      return;
    }

    var airlineCode = "AS";
    if ((flightNumber >= 2000 && flightNumber < 3000) ||
        (flightNumber >= 3854 && flightNumber <= 3897) || 
        (flightNumber >= 7200 && flightNumber <= 7209) || 
        (flightNumber >= 8200 && flightNumber <= 8200) || 
        (flightNumber >= 9370 && flightNumber <= 9399) || 
        (flightNumber >= 9470 && flightNumber <= 9479) || 
        (flightNumber >= 9706 && flightNumber <= 9715) || 
        (flightNumber >= 9730 && flightNumber <= 9739) || 
        (flightNumber >= 9770 && flightNumber <= 9779) ||
        (flightNumber >= 9985 && flightNumber <= 9999)) {
      airlineCode = "QX";
    }
    else if ((flightNumber >= 3300 && flightNumber <= 3499) ||
            (flightNumber >= 9320 && flightNumber <= 9339) || 
            (flightNumber >= 9790 && flightNumber <= 9799)) {
      airlineCode = "OO";
    }

    //validate user should have access to this airline
    var userAirlinesArray = [];
    try {
      const userAirlines = localStorage.getItem('user_airlines');
      console.log('user airlines raw = ' + userAirlines);
      if (!userAirlines) {
        console.log('user airlines not set');
        this.clearFlightInfoHeaderAndSetError(this.ERR_NO_AIRLINE_SET_LOGIN_AGAIN);
        return;
      }  
      userAirlinesArray = JSON.parse(userAirlines);
      console.log('user airlines parsed = ' + userAirlinesArray);
      if (!userAirlinesArray.includes(airlineCode)) {
        this.clearFlightInfoHeaderAndSetError(this.ERR_WRONG_AIRLINE);
        return;
      }
    }
    catch (e) {
      console.log('Error parsing user airlines', e);
      return;
    }

    //Work around to solve UTC date issue
    //If the request is coming from deep linking, date is in UTC.
    //First call the flight info service v3 to get the flight info with UTC. Get the local date and then 
    //Call the flight info service v1 with the local date.
    if(this.utcDate) {
      
      this.loading = true;

      this.flightInfoService.getFlightInfo(airlineCode, departureDate.format('YYYY-MM-DD'), true, flightNumber.toString(),departureAirport).subscribe((result) => {
      
        //When we call the flight info service with UTC date in rare cases we could get more than one flight for the same date. In this case we fail. 
        if (result == null || result.Flight == null || result.Flight.length != 1){
          this.clearFlightInfoHeaderAndSetError(this.ERR_FLIGHT_NOT_FOUND);

          //utcDate is set to false so that it will search flight based on the local date.
          this.utcDate = false;
          this.departureDate = "";
          this.flightForm.patchValue({ departureDate: ""})
          
          return;
        }
  
        //Make the second call with local date.
        const localDate = moment(result.Flight[0].FlightIdentifier?.LocalDate, 'YYYY-MM-DD');
        this.validateFlightAndJoinChannel(airlineCode, localDate, false, flightNumber, departureAirport)
  
      });
    }
    else {
      this.validateFlightAndJoinChannel(airlineCode, departureDate, false, flightNumber, departureAirport)
    }
  }

  validateFlightAndJoinChannel(airlineCode: string, departureDate: moment.Moment, utcDate: boolean, flightNumber: Number, departureAirport: string) {

    const formattedDepartureDate = departureDate.format('YYYYMMDD');
    console.log('Formatted departure date: ' + formattedDepartureDate);

    const paddedFlightNumber = ("0000" + this.flightForm.get('flightNumber').value).slice(-4);

    this.flightInfoService.getFlightInfo(airlineCode, departureDate.format('YYYY-MM-DD'), utcDate, flightNumber.toString(),departureAirport).subscribe((result) => {
      
      if (result == null || result.Flight == null || result.Flight.length == 0){
        this.clearFlightInfoHeaderAndSetError(this.ERR_FLIGHT_NOT_FOUND);
        return;
      }
      
      const leg = this.getFlightLeg(result.Flight[0]);
      this.updateFlightInfoHeader(leg);

      this.hideErrorMessage();
      //Join the channel only if it is a valid flight.
      this.channelName = formattedDepartureDate + "-" + airlineCode + paddedFlightNumber + "-" + departureAirport;

      if (this.channel) {
        const oldChannelName = this.channel.uniqueName;
        this.channel.leave().then(channel => {
          console.log('Left channel ' + oldChannelName);
          this.joinChannel(this.channelName);
        })
        .catch(error => {
          console.log('Failed to leave channel.');
          this.joinChannel(this.channelName);
        });
      }
      else {
        this.joinChannel(this.channelName);
      }

       //Hide the spinner after loading the chat.
       this.loading = false;
    });
  }

  getFlightLeg(flight: Flight) {
    //if only one leg, easy, use it
    if (flight.FlightLegs.length == 1) {
      return flight.FlightLegs[0];
    }
    //if more than 1, we probably have an irrops situation
    // look for first leg that has desired departure airport and no irrops
    const nonIrropsLegs = flight.FlightLegs.filter(l => 
      l.FlightLegIdentifier.IataDepartureAirportCode == flight.FlightIdentifier.IataDepartureAirportCode
      && l.LegData.AirgroupOperationalStatus.AirgroupOpsType == ''
      );
    if (nonIrropsLegs.length > 0) {
      return nonIrropsLegs[0];
    }
    //fallback return first leg
    return flight.FlightLegs[0];
  }

  clearFlightInfoHeaderAndSetError(errorNumber: number) {
    this.clearFlightInfoHeader();
    this.showErrorMessage(errorNumber);
    this.channel = null;

    //Hide the spinner.
    this.loading = false;
  }

  clearFlightInfoHeader() {
    console.log('clearFlightInfoHeader');
    this.flightInfoFlightNumber = '';
    this.flightInfoDepartureAirport = '';
    this.flightInfoArrivalAirport = '';
    this.flightInfoDepartureTime = '';
    this.flightInfoDepartureDate = '';  
  }

  updateFlightInfoHeader(leg: FlightLeg) {
    
    console.log('updateFlightInfoHeader');
    this.clearFlightInfoHeader();
    this.flightInfoFlightNumber = leg.FlightLegIdentifier.IataOperatingAirlineCode + ' ' + leg.FlightLegIdentifier.FlightNumber;
    this.flightInfoDepartureAirport = leg.FlightLegIdentifier.IataDepartureAirportCode;
    this.flightInfoArrivalAirport = leg.FlightLegIdentifier.IataArrivalAirportCode;

    const actualDepartureTime = moment.parseZone(leg.LegData.FlightTimes.ActualFlightTimes.OutUtc);
    if (actualDepartureTime.isValid()) {
      console.log('Flight has departed');
      this.flightInfoDepartureTime = 'Departed ' + this.formattedDepartureTime(actualDepartureTime) + ' Local'; 
      this.flightInfoDepartureDate = this.formattedDepartureDate(actualDepartureTime);
    }
    else {
      const estimatedDepartureTime = moment.parseZone(leg.LegData.FlightTimes.EstimatedFlightTimes.EtdUtc);
      if (estimatedDepartureTime.isValid()) {
        console.log('Flight has estimated departure time');
        this.flightInfoDepartureTime = 'ETD ' + this.formattedDepartureTime(estimatedDepartureTime) + ' Local';
        this.flightInfoDepartureDate = this.formattedDepartureDate(estimatedDepartureTime);
      }
      else {
        const scheduledDepartureTime = moment.parseZone(leg.LegData.FlightTimes.ScheduledFlightTimes.StdUtc);
        if (scheduledDepartureTime.isValid()) {
          console.log('Flight has scheduled departure time');
          this.flightInfoDepartureTime = 'ETD ' + this.formattedDepartureTime(scheduledDepartureTime) + ' Local';
          this.flightInfoDepartureDate = this.formattedDepartureDate(scheduledDepartureTime);
        }
        else {
          console.log('WARNING: flight has no departure time');
        }  
      }
    }
  }

  formattedDepartureTime(departureDate: moment.Moment) {
    if (departureDate.isValid()) {
      return departureDate.format('HH:mm');
    }
    return '';
  }
  formattedDepartureDate(departureDate: moment.Moment) {  
    if (departureDate.isValid()) {
      return departureDate.format('MMM D YYYY');
    }
    return '';
  }

  loginToTwilio()  {

    if (this.airtalkTokenResponse == null || this.airtalkTokenResponse.twilioToken == null) {
      console.log('Airtalk service returned null response or token');
      this.showErrorMessage(this.ERR_NOT_SIGNED_IN_TO_TWILIO);
      return;
    }

    console.log('Preparing to create Twilio chat client');

    Twilio.Client.create(this.airtalkTokenResponse.twilioToken).then(client => {
      console.log('Created Twilio chat client');
      this.client = client;
      this.connectedToTwilio = true;

      var attributes = {
        'FirstName': this.airtalkTokenResponse.firstName,
        'LastName': this.airtalkTokenResponse.lastName
      };

      this.client.user.updateAttributes(attributes);

      //Note: Moved loadChatUi functionality here since it needs to wait until all the async calls above the chain finishes.
      //Load chat if query string parameters are supplied.
      if (this.departureDate && this.flightNumber && this.departureAirport) {
        this.flightForm.setValue({departureDate: this.departureDate, flightNumber: this.flightNumber, departureAirport: this.departureAirport});
        //Call the functionality to load the chat.
        this.loadChatUi();
      }
    }).catch(error => {
      console.log('Failed to create Twilio chat client', error);
    });
  }

  joinChannel(channelName: string) { 
      var attributes = {
      'Role': localStorage.getItem("user_role"),
      'Position': ''
      };

      this.client.getChannelByUniqueName(String(channelName)).then(channel => {
        //channel exists
        console.log('Joining channel... ' + channelName);

        channel.getMemberByIdentity(this.client.user.identity).then(member => {
          //user is a member of this channel
          console.log(this.client.user.identity + ' is already a member of channel ' + channelName);
          this.onJoinedChannel(channel);   
          member.updateAttributes(attributes);
        })
        .catch(error => {
          //user is not a member, join the channel
          channel.join().then(channel => {
            console.log('Joined channel ' + channelName);
            
            //Hide error messages after joining the channel successfully
            this.hideErrorMessage();
            this.disableSend = false;

            this.onJoinedChannel(channel);
            channel.getMemberByIdentity(this.client.user.identity).then(member => {
              member.updateAttributes(attributes);
            });

          }).catch(error => {
            console.log('Failed to join channel ' + channelName + '. Channel status = ' + channel.status, error);
          });  
        });
      }).catch(error => {
        //channel does not exist, create it
        const channelOptions = {
          uniqueName: channelName,
          friendlyName: channelName,
          isPrivate: false
        }
        this.client.createChannel(channelOptions).then(channel => {
          console.log('Created channel ' + channel.uniqueName);
          //now join it
          channel.join().then(channel => {
            console.log('Joined channel ' + channelName);
            
            //Hide error messages after joining the channel successfully
            this.hideErrorMessage();
            this.disableSend = false;

            this.onJoinedChannel(channel);
            channel.getMemberByIdentity(this.client.user.identity).then(member => {
              member.updateAttributes(attributes);
            });
          }).catch(error => {
            console.log('Failed to join channel ' + channelName + '. Channel status = ' + channel.status, error);
          }); 
        })
        .catch(error => {
          console.log('Failed to create channel ' + channelName, error);
        });
      });
  }

  onJoinedChannel(channel: Channel) {
    this.channel = channel;
    this.channel.on('messageAdded', message => {
      console.log('Message added: ' + message.body);
      this.getMessages();
    });  
    this.getMessages(); 
  }

  sendMessage()  {
    console.log('Sending message...');
    var attributes = {
      'Role': localStorage.getItem("user_role"),
      'Position': '',
      'Channel': this.channel.uniqueName,
      'Type': 'custom',
      'FirstName': this.airtalkTokenResponse.firstName,
      'LastName': this.airtalkTokenResponse.lastName
    };
    const messageText = this.messageForm.get('messageText').value;
    this.disableSend = true; //don't allow sending again
    this.channel.sendMessage(messageText, attributes).then(result => {
      //reset the height of the message input area after sending a message
      // note the height setting is delicate, must correspond to text-area CSS style
      this._renderer2.setStyle(this.messageInputTextArea.nativeElement, 'height', '30px');
      this.getMessages();
    })
    .catch(error => {
      console.log('Failed to send message', error);
    })
    .finally(() => {
      this.messageForm.get('messageText').setValue('');
      this.disableSend = false; //allow sending again
    })
  }

  getMessages() {
    //TODO: Fix bug where after getting messages for a channel with no messages,
    //      the next attempt to get messages for a channel that has messages returns nothing
    console.log('Getting messages');
    this.channel.getMessages(100).then(paginator => {
      var messages: AirtalkMessage[] = [];
      for (let item of paginator.items) {
        console.log("Message found: " + item.body);

        var airtalkMessage = new AirtalkMessage();
        airtalkMessage.body = item.body;
        airtalkMessage.role = item.attributes["Role"];
        airtalkMessage.position = item.attributes["Position"];
        airtalkMessage.type = item.attributes["Type"];
        airtalkMessage.author = item.author;
        airtalkMessage.timestamp = item.timestamp;
        airtalkMessage.index = item.index;
        airtalkMessage.pillColor = this.getPillColor(airtalkMessage.role);
        airtalkMessage.displayName = this.getDisplayName(airtalkMessage.role, airtalkMessage.position);
        airtalkMessage.displayTime = this.getDisplayTime(airtalkMessage.timestamp);
        airtalkMessage.senderName = this.getSenderName(item.attributes["FirstName"], item.attributes["LastName"])
        messages.push(airtalkMessage);
        console.log("Airtalk message body: " + airtalkMessage.body);
        console.log("Airtalk message role: " + airtalkMessage.role);
        console.log("Airtalk message position: " + airtalkMessage.position);
        console.log("Airtalk message type: " + airtalkMessage.type);
        console.log("Airtalk message author: " + airtalkMessage.author);
        console.log("Airtalk message timestamp: " + airtalkMessage.timestamp);
        console.log("Airtalk message index: " + airtalkMessage.index);
      }
      this.airtalkMessages = messages;

      //scroll the last message into view...
      setTimeout(() => {  
        let div = document.getElementById('channel-container');
        div.lastElementChild.scrollIntoView({ behavior: 'smooth' });
      }, 10);

    }).catch(error => {
      console.log('Failed to get messages!', error);
    });
  }

  getPillColor(role: string) {

    let messageRole = this.airtalkConfiguration.roles.find(r => r.role == role) ?? this.defaultConfig.roles.find(r => r.role == 'unknown');
    if (messageRole.themes?.default) {
      return messageRole.themes.default.backgroundColor;
    }

    //Dont reach here. Fail safe.
    //default if unknown
    return "#A6A6A6";
  }

  getDisplayName(role: string, position: string) {
    let messageRole = this.airtalkConfiguration.roles.find(r => r.role == role) ?? this.defaultConfig.roles.find(r => r.role == 'unknown');
    let messagePosition= messageRole.positions?.find(p => p.position == position);
    if (messagePosition && messagePosition.displayName){
      return messageRole.displayName + '-' + messagePosition.displayName;
    } else {
      return messageRole.displayName;
    }
  }

  getDisplayTime(timestamp: Date) {
    if (moment(timestamp).isValid()) {
      return moment(timestamp).format("HH:mm");
    };
    return '';
  }

  getSenderName(firstName: string, lastName: string) {
    var senderName = "";
    if(firstName && lastName) {
      if(firstName.trim()) {
        senderName = firstName + " ";
      }
      if(lastName.trim()) {
        senderName = senderName + lastName.charAt(0) + ".";
      }
    }
    return senderName;
  }

  showErrorMessage(errorCode: Number) {
    this.showError = true;
    this.errorCode = errorCode;
  }
  hideErrorMessage() {
    this.showError = false;
    this.errorCode = 0;
  }
}

export class AirtalkMessage {
  body: string;
  role: string;
  position: string;
  type: string;
  author: string;
  timestamp: Date;
  index: number;
  //computed properties:
  pillColor: string;
  displayName: string;
  displayTime: string;
  senderName: String;
}

