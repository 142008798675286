import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  /**
   * Converts all requests to authentication provider to local requests
   * so that they are proxied to avoid CORS issues
   * @param req the request being made by RAIN
   * @param next the next request handler in the chain
   * @returns the HTTP event
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Make all OAuth requests local to enable reverse proxy to avoid CORs issue with PingFederate
    if (req.url.includes(environment.provider)) {
      const originalURL = req.url;
      const requestToForward = req.clone({
        url: originalURL.replace(environment.provider, window.location.origin),
      });
      return next.handle(requestToForward);
    } else {
      return next.handle(req);
    }
  }
}